import Vue from 'vue'
import App from './App.vue'

Vue.prototype.$api = 'https://api.tv-king.com';
Vue.prototype.$token = '';
Vue.prototype.$admpwd = 'Tvking9319';

import router from './router'




import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css'  // 引入Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import $ from 'jquery'
window.$ = window.jQuery = $

Vue.config.productionTip = false




new Vue({
  router,
  BootstrapVue,
  render: h => h(App)
}).$mount('#app')


//border color #F0BF4D