import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

let api = 'https://api.tv-king.com'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/user/sign',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/IndexView.vue'),
    children: [
      {
        path: 'directions',
        name: 'Directions',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/DirectionsView.vue')
      },
      {
        path: 'show',
        name: 'Show',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/ShowUserOfTeam2.vue')
      },
      {
        path: 'news',
        name: 'News',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/NewsView.vue')
      },
      {
        path: 'teacher/sign',
        name: 'TeacherSignUp',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/TeacherSignUpView.vue')
      },
      {
        path: 'user/sign',
        name: 'UserSignUp',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserSignUpView.vue')
      },
      {
        path: 'user/student/edit',
        name: 'UserStudentEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserStudentEditView.vue')
      },
      {
        path: 'user/sign/success',
        name: 'UserSignUpSuccess',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserSignupSuccessView.vue')
      },
      {
        path: 'user/sign/person/success',
        name: 'UserPersonUpSuccess',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserPersonSuccessView.vue')
      },
      {
        path: 'user/sign/student/success',
        name: 'UserStudentUpSuccess',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserStudentSuccessView.vue')
      },
      {
        path: 'user/login',
        name: 'UserLogin',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserLoginView.vue')
      },
      {
        path: 'video/upload',
        name: 'UploadVideo',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UploadVideo1.vue')
      },
      {
        path: 'video/search',
        name: 'SearchVideo',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/SearchVideo.vue')
      },
      {
        path: 'video/manage',
        name: 'ManageVideo',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/video/MangeVideo.vue')
      },
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/IndexView.vue'),
    children: [
      {
        path: 'group',
        name: 'AdminGroupView',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/ShowGroupView.vue')
      },
      {
        path: 'users',
        name: 'AdminUsersView',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/ShowUsersView.vue')
      },
      {
        path: 'video',
        name: 'AdminVideoView',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/ShowVideoAllView.vue')
      },
      {
        path: 'analysis',
        name: 'AnalysisViewView',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/AnalysisView.vue')
      }
    ]
  }
]



const router = new VueRouter({
  mode:"history",
  routes
})


router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('userToken');
  
  if(to.name == 'UserStudentEdit'){
    if (!token) {
      return next({ name: 'UserLogin' });
    } else {
      axios.post(`${api}/user/login/check`, {
        'token': token
      })
      .then(function (response) {
        if(response.data.satus == 'success'){
          localStorage.setItem('userToken', response.data.token);
          next(); 
        }else{
          alert('超過一小時未使用，請重新登入')
          localStorage.clear()
          return next({ name: 'UserLogin' });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }else if(to.name == 'UploadVideo'){
    if (!token) {
      return next({ name: 'UserLogin' });
    } else {
      axios.post(`${api}/user/login/check`, {
        'token': token
      })
      .then(function (response) {
        if(response.data.satus == 'success'){
          localStorage.setItem('userToken', response.data.token);
          next(); 
        }else{
          alert('超過一小時未使用，請重新登入')
          localStorage.clear()
          return next({ name: 'UserLogin' });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }else if(to.name == 'ManageVideo'){
    if (!token) {
      return next({ name: 'UserLogin' });
    } else {
      axios.post(`${api}/user/login/check`, {
        'token': token
      })
      .then(function (response) {
        if(response.data.satus == 'success'){
          localStorage.setItem('userToken', response.data.token);
          next(); 
        }else{
          alert('超過一小時未使用，請重新登入')
          localStorage.clear()
          return next({ name: 'UserLogin' });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
  else {
    next(); 
  }

  

});


export default router
